import React from 'react';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';
import WhoAmI from '@components/WhoAmI/WhoAmI';
import Newsletter from '@components/Newsletter/Newsletter';

const ChiSono = ({ data, location }) => {
  const title = 'Chi sono';
  const subtitle =
    'Tina Olsen, Intuitive Eating Counselor & Body Image Coach, Insegnante di Pilates & Garuda';

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <PageContainer noBottomMargin>
        <PageHeader title={title} subtitle={subtitle} center />
        <WhoAmI />
        <Newsletter />
      </PageContainer>
    </Layout>
  );
};

export default ChiSono;
