import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Slider from 'infinite-react-carousel';
import Breakpoints from '@utils/breakpoints';

import Button from '@components/Button/Button';

import Tina1 from '@static/img/tina1.jpg';
import Tina2 from '@static/img/tina2.jpg';

import BMI from '@static/img/logos/bmi.png';
import Coactive from '@static/img/logos/coactive.png';
import Eng from '@static/img/logos/eng.svg';
import Garuda from '@static/img/logos/garuda.svg';
import Oiep from '@static/img/logos/oiep.png';
import Sita from '@static/img/logos/sita.png';

import Styles from './WhoAmI.styles';

const useStyles = createUseStyles(Styles);

const cleanBreakPoint = b => {
  return parseInt(b.replace('px', ''), 10);
};
const getDefaultSlidesToShow = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= cleanBreakPoint(Breakpoints.l) &&
      window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ? 4
      : window.innerWidth <= cleanBreakPoint(Breakpoints.s)
      ? 2
      : 6;
  } else {
    return 4;
  }
};

const HomeLessons = props => {
  const classes = useStyles(props);

  const defaultSettings = {
    slidesToShow: getDefaultSlidesToShow(),
    autoplay: typeof window !== 'undefined' ? window.innerWidth <= 900 : true,
    arrows: false,
    arrowsBlock: false,
  };

  const [settings, handleSettings] = useState(defaultSettings);

  /* eslint-disable */
  useEffect(() => {
    const checkSizes = () => {
      const clonedSettings = JSON.parse(JSON.stringify(settings));
      if (
        window.innerWidth <= cleanBreakPoint(Breakpoints.l) &&
        window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 4;
        handleSettings(clonedSettings);
      } else if (window.innerWidth <= cleanBreakPoint(Breakpoints.s)) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 2;
        handleSettings(clonedSettings);
      } else {
        clonedSettings.autoplay = false;
        clonedSettings.slidesToShow = 6;
        handleSettings(clonedSettings);
      }
    };

    window.addEventListener('resize', checkSizes);

    return function cleanup() {
      window.removeEventListener('resize', checkSizes);
    };
  }, []);
  /* eslint-enable */

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.fieldLeft}>
              <h2 className={classes.fieldLeftTitle}>La mia storia</h2>
              <p>
                Da quando avevo 9 anni ho capito di “dover fare attenzione” a
                cosa mangiavo per non ingrassare.
              </p>
              <p>
                E così, per quasi 40 anni mi sono fatta guidare dalla cultura
                della dieta, all'inizio per dimagrire e poi per paura di
                ingrassare, senza mai chiedermi se il controllo, l'allenamento
                ossessivo, il pianificare ogni pasto, il contare calorie, le
                restrizioni, la deprivazione e la rinuncia a cene improvvisate o
                a una fetta di torta appena sfornata, valeva veramente la pena.
              </p>
              <p className={classes.large}>… è la storia di tante donne</p>
              <p>
                Sfortunatamente però, le diete (compresi i "
                <strong>non-è-una-dieta-ma-uno-stile-di-vita</strong>") che ci
                promettono di diventare più sane, aumentare l'energia, sgonfiare
                la pancia, disintossicarci, rimetterci in forma, proteggere il
                clima e così via, non funzionano per cambiare l'aspetto fisico.
              </p>
              <p>
                Le diete con tutte le loro regole ci scollegano dalla saggezza
                nostro corpo.
              </p>
              <p>
                <strong>
                  Sei pronta a dire BASTA e a riprendere in mano la tua vita?
                </strong>
                <br />
                Prenota qui una consulenza gratuita.
              </p>
              <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
            </div>
            <div className={classes.fieldRight}>
              <img src={Tina1} alt="Tina Olsen" />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.containerTransparent}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.fieldLeftSmall}>
              <img src={Tina2} alt="Tina Olsen" />
            </div>
            <div className={classes.fieldRightLarge}>
              <h3>Altro su di me che forse vorresti sapere</h3>
              <ul>
                <li>
                  Sono danese e vivo da più di 24 anni nelle alpi italiane
                  <li>Mi piace fare la maglia e le parole crociate</li>
                  <li>
                    Parlo diverse lingue e mi piacerebbe imparare anche l'arabo
                    - ma sarà per un'altra vita
                  </li>
                  <li>
                    Amo la cucina italiana ma mi mancano un po' le aringhe
                    marinate
                  </li>
                  <li>
                    Ho due bei bambini che parlano anche il danese ma che non
                    mangiano le aringhe
                  </li>
                  <li>
                    Mi piace lo sport e pensavo di essere brava a sciare finché
                    non ho sposato un maestro di sci valdostano
                  </li>
                  <li>
                    Preferisco Nutella e acqua gassata a ostriche e Champagne
                  </li>
                  <li>
                    Mi piace la città con il suo traffico e l'odore di gasolio e
                    asfalto, mi piace il mare e gli orizzonti del mio paese
                    tutto piatto, e sto bene anche in montagna e in generale
                    ovunque mi trovo.
                  </li>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.field}>
              <h3>La mia formazione relativa al mio lavoro</h3>
              <ul>
                <li>
                  Mindfulness Stress Reduction Programme (2022,{' '}
                  <a
                    href="https://www.britishmindfulnessinstitute.co.uk/accreditation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    British Mindfulness Institute
                  </a>
                  )
                </li>
                <li>Body Image Coach</li>
                <li>
                  Counselor in Intuitive Eating certificata (2021,{' '}
                  <a
                    href="https://www.intuitiveeating.org/certified-counselors/?country=Italy&state=undefined&city="
                    target="_blank"
                    rel="noreferrer"
                  >
                    Intuitive Eating
                  </a>
                  )
                </li>
                <li>
                  Insegnante certificata di Garuda (2021,{' '}
                  <a
                    href="https://www.thegaruda.net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Garuda
                  </a>
                  )
                </li>
                <li>
                  Size Inclusive Fitness Specialist (2021,{' '}
                  <a
                    href="https://www.sizeinclusivetraining.com/fitnessspecialistdirectory?r_done=1#_5kikphpgd"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Size Inclusive Training Academy
                  </a>
                  )
                </li>
                <li>Insegnante certificata di Pilates (2017 FIF & BASI)</li>
                <li>
                  Bachelor in Social Science (2016,{' '}
                  <a
                    href="https://www.open.ac.uk/about/main/locations/milton-keynes-campus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Open University
                  </a>
                  )
                </li>
                <li>
                  Life Coach certificata (2009,{' '}
                  <a
                    href="https://coactive.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Co-Active
                  </a>
                  )
                </li>
              </ul>
            </div>
          </div>

          <div className={classes.logos}>
            <Slider {...settings}>
              <div className={classes.item}>
                <a
                  href="https://www.britishmindfulnessinstitute.co.uk/accreditation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.itemImg}
                    src={BMI}
                    alt="British Mindfulness Institute"
                  />
                </a>
              </div>
              <div className={classes.item}>
                <a
                  href="https://www.intuitiveeating.org/certified-counselors/?country=Italy&state=undefined&city="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.itemImg}
                    src={Oiep}
                    alt="The Original Intuitive Eating Pros"
                  />
                </a>
              </div>
              <div className={classes.item}>
                <a
                  href="https://www.thegaruda.net/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className={classes.itemImg} src={Garuda} alt="Garuda" />
                </a>
              </div>
              <div className={classes.item}>
                <a
                  href="https://www.sizeinclusivetraining.com/fitnessspecialistdirectory?r_done=1#_5kikphpgd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.itemImg}
                    src={Sita}
                    alt="Size Inclusive Training Academy"
                  />
                </a>
              </div>
              <div className={classes.item}>
                <a
                  href="https://www.open.ac.uk/about/main/locations/milton-keynes-campus"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.itemImg}
                    src={Eng}
                    alt="The Open University"
                  />
                </a>
              </div>
              <div className={classes.item}>
                <a
                  href="https://coactive.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={classes.itemImg}
                    src={Coactive}
                    alt="Coactive"
                  />
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLessons;
